.form_quote, .widget {
  padding: 0.5em;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #f5f5f5;
  color: #333; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.block {
  padding: 20px 0; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.banner {
  position: relative;
  z-index: 99000; }

.jumbo {
  position: relative;
  z-index: 70000; }
  @media (min-width: 992px) {
    .jumbo .row {
      -ms-flex-align: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      align-items: center; }
    .jumbo .jumbo_marketing {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .jumbo .jumbo_conversion {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

html {
  font-size: 16px;
  line-height: 1.6em;
  -webkit-text-size-adjust: 100%; }

body {
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  @media (min-width: 768px) {
    body {
      font-size: 1.1rem; } }
  @media (min-width: 768px) {
    body {
      font-size: 1.2rem; } }

h1, h2, h3, h4, h5, h6 {
  margin: 1.5em 0 0.5em;
  font-weight: 400;
  line-height: 1.1em; }

h6 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.0125em; }

h5 {
  font-size: 1.2rem; }

h4 {
  font-size: 1.4rem;
  letter-spacing: -0.015em; }

h3 {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.0175em; }

h2 {
  font-size: 1.8rem;
  letter-spacing: -0.02em; }

h1 {
  font-size: 2.0rem;
  font-weight: 800;
  letter-spacing: -0.02em; }

p {
  margin: 0 0 1em; }
  p.lead {
    font-size: 120%;
    line-height: 1.53em; }

ul, ol {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }

.b0 {
  font-weight: 400; }

b, strong, .b1 {
  font-weight: 600; }

.bolder, .strong, .b2 {
  font-weight: 800; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 0.5em 0.5em 0.5em 1em;
  font-size: 1.15rem;
  border-left: 0.5rem solid; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #333; }
  blockquote.bq--primary {
    border-color: #72BF44; }
  blockquote.bq--secondary {
    border-color: #009BDF; }
  blockquote.bq--highlight {
    border-color: #FEA401; }
  blockquote.bq--accent {
    border-color: #6f42c1; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #ffc107; }
  blockquote.bq--info {
    border-color: #6610f2; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #009BDF; }
  a:hover, a:focus {
    color: #006693;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #60ceff; }

.phone {
  text-decoration: none;
  cursor: default;
  color: #009BDF; }
  .phone:hover {
    text-decoration: none;
    color: #009BDF;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #333; }
  .phone.mask:hover {
    outline: none;
    color: #333; }

.list_inline li:not(:last-child), .list_unstyled li:not(:last-child), .list_icon li:not(:last-child), .list li:not(:last-child) {
  margin-bottom: 0.25em; }

.list_inline, .list_unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list_inline li {
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0 !important; }

.list_buttons a {
  border-radius: 3px; }

.list_icon {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none; }
  .list_icon li {
    padding-left: 2em;
    font-size: 1.125rem;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    background-position: left center; }

.icon_check-primary li {
  background-image: url("/_/images/icons/cm_primary.svg"); }

.icon_check-secondary li {
  background-image: url("/_/images/icons/cm_secondary.svg"); }

.icon_check-success li {
  background-image: url("/_/images/icons/cm_success.svg"); }

img {
  display: block;
  width: 100%;
  border-style: none; }

.img--caption span {
  display: block;
  margin: 5px 0 0;
  line-height: 1.1em; }

.img--main {
  margin: 0 0 15px;
  max-width: 360px; }

.img--thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 576px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 15px; } }

@media (min-width: 576px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 15px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  max-width: none; }

.img--coupon {
  max-width: 640px; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive.embed-responsive-4by3 {
    padding-bottom: 75%; }
  .embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block; }

.mb0 {
  margin-bottom: 0 !important; }

.mb15 {
  margin-bottom: 15px !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: 16 !important; }

.txt--sm {
  font-size: 85% !important; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--wb0 {
  font-weight: 400; }

.txt--wb1 {
  font-weight: 600; }

.txt--wb2 {
  font-weight: 800; }

.color--text {
  color: #333; }

.color--white {
  color: #fff; }

.color--primary {
  color: #72BF44; }

.color--secondary {
  color: #009BDF; }

.color--highlight {
  color: #FEA401; }

.color--link {
  color: #009BDF; }

.color--review {
  color: #fd7e14; }

.color--success {
  color: #28a745; }

.color--danger {
  color: #dc3545; }

.color--warning {
  color: #ffc107; }

.color--info {
  color: #6610f2; }

.bg--text {
  background-color: #333; }

.bg--white {
  background-color: #fff; }

.bg--primary {
  background-color: #72BF44; }

.bg--secondary {
  background-color: #009BDF; }

.bg--highlight {
  background-color: #FEA401; }

.bg--link {
  background-color: #009BDF; }

.bg--review {
  background-color: #fd7e14; }

.bg--success {
  background-color: #28a745; }

.bg--danger {
  background-color: #dc3545; }

.bg--warning {
  background-color: #ffc107; }

.bg--info {
  background-color: #6610f2; }

.gdfancybg--text {
  background: #333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #333; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fff; }

.jumbo, .gdfancybg--primary, .page-title {
  background: #72BF44;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #72BF44; }

.gdfancybg--secondary {
  background: #009BDF;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #009BDF; }

.gdfancybg--highlight {
  background: #FEA401;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #FEA401; }

.gdfancybg--review {
  background: #fd7e14;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fd7e14; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #ffc107; }

.gdfancybg--info {
  background: #6610f2;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #6610f2; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.site_alert > .alert:first-of-type {
  margin-top: 1em; }

.site_alert > .alert:last-of-type {
  margin-bottom: 0; }

.alert {
  border: 1px solid #c4c4c4;
  background-color: white;
  color: #333;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 3px; }
  .alert strong, .alert a {
    color: #b7b7b7; }
  .alert strong {
    font-weight: 800; }

.alert-primary {
  border: 1px solid #5b9b35;
  background-color: #e5f3dc;
  color: #333; }
  .alert-primary strong, .alert-primary a {
    color: #50882f; }

.alert-secondary {
  border: 1px solid #0078ac;
  background-color: #ace6ff;
  color: #333; }
  .alert-secondary strong, .alert-secondary a {
    color: #006693; }

.alert-highlight {
  border: 1px solid #cb8301;
  background-color: #ffedcc;
  color: #333; }
  .alert-highlight strong, .alert-highlight a {
    color: #b27301; }

.alert-accent {
  border: 1px solid #59339d;
  background-color: #e4dcf3;
  color: #333; }
  .alert-accent strong, .alert-accent a {
    color: #4e2d89; }

.alert-success {
  border: 1px solid #1e7e34;
  background-color: #afecbd;
  color: #333; }
  .alert-success strong, .alert-success a {
    color: #19692c; }

.alert-danger {
  border: 1px solid #bd2130;
  background-color: #fae3e5;
  color: #333; }
  .alert-danger strong, .alert-danger a {
    color: #a71d2a; }

.alert-info {
  border: 1px solid #510bc4;
  background-color: #e2d1fd;
  color: #333; }
  .alert-info strong, .alert-info a {
    color: #4709ac; }

.alert-warning {
  border: 1px solid #d39e00;
  background-color: #fff4d3;
  color: #333; }
  .alert-warning strong, .alert-warning a {
    color: #ba8b00; }

.alert-link {
  border: 1px solid #0078ac;
  background-color: #ace6ff;
  color: #333; }
  .alert-link strong, .alert-link a {
    color: #006693; }

.announce {
  padding: 12px 0;
  border-bottom: 0.25rem solid #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #009BDF;
  color: #fff; }
  .announce p {
    margin-bottom: 0.5em;
    font-weight: 800;
    text-transform: uppercase; }
    .announce p:last-child {
      margin-bottom: 0; }

[class*="announce_"] {
  text-align: center; }
  @media (min-width: 768px) {
    [class*="announce_"] p {
      float: left;
      margin: 0; } }

@media (min-width: 768px) {
  .announce_2 p {
    width: 50%; } }

@media (min-width: 768px) {
  .announce_3 p {
    width: 33.33333%; } }

@media (min-width: 768px) {
  .announce_4 p {
    width: 25%; } }

.block-main {
  padding: 1.5em 0;
  clear: both; }
  .block-main .block_title {
    margin-top: 0; }

.jumbo {
  color: #fff; }
  @media (min-width: 992px) {
    .jumbo .jumbo_marketing {
      padding-right: 5px; }
    .jumbo .jumbo_conversion {
      padding-left: 5px; } }

.jumbo_marketing > *:first-child {
  margin-top: 0; }

.jumbo_marketing > *:last-child {
  margin-bottom: 0; }

.jumbo_marketing h1:before {
  content: "Need Help With "; }

.jumbo_marketing h1:after {
  content: "?"; }

.jumbo_marketing p, .jumbo_marketing ul {
  font-size: 1.25rem; }

.jumbo_marketing ul > li:not(:last-child) {
  margin-bottom: 0.5em; }

@media (max-width: 991px) {
  .jumbo_marketing {
    margin-bottom: 1rem; } }

@media (min-width: 768px) {
  .jumbo_marketing p {
    font-size: 1.35rem; } }

@media (min-width: 992px) {
  .jumbo_marketing h1 {
    font-size: 2.25rem; }
  .jumbo_marketing ul > li {
    font-size: 1.3rem; } }

.jumbo_conversion .form_quote .form_title {
  line-height: 1em;
  background-color: #009BDF;
  background-image: -webkit-linear-gradient(left, #007fb6 0%, #06b3ff 75%);
  background-image: -o-linear-gradient(left, #007fb6 0%, #06b3ff 75%);
  background-image: linear-gradient(to right, #007fb6 0%, #06b3ff 75%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF007FB6', endColorstr='#FF06B3FF', GradientType=1); }

.company_block {
  text-align: center; }
  .company_block img {
    max-width: 360px;
    margin: 0 auto 15px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .company_block .company_logo {
      float: left;
      width: 49%;
      margin-left: 0; }
    .company_block .company_call,
    .company_block .company_address {
      float: right;
      width: 49%;
      text-align: right; }
    .company_block .list_company {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 0.5em; } }
  @media (min-width: 992px) {
    .company_block {
      text-align: left; } }

.company_logo {
  margin: 0 auto 0.5em; }

.company_call {
  line-height: 1.1em; }

.company_call .phone {
  font-size: 32px;
  font-weight: 800;
  letter-spacing: -0.025em;
  color: #72BF44; }
  @media (min-width: 992px) {
    .company_call .phone {
      font-size: 42px;
      letter-spacing: -0.05em; } }

.call_emergency {
  display: inline-block;
  margin-top: 0.05em;
  font-size: 18px;
  text-transform: uppercase !important;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: #dc3545; }

.company_address {
  font-size: 85% !important;
  line-height: 1.25em !important; }
  .company_address .address_title {
    font-size: 115% !important; }
  @media (min-width: 992px) {
    .company_address {
      padding: 0.5em 0.75em;
      border-left: 5px solid #ccc; } }

.list_company {
  clear: both;
  font-weight: 600; }
  .list_company li {
    display: inline-block;
    padding-left: 1.5em;
    line-height: 1.25em; }
    .list_company li:not(:last-child) {
      margin-right: 0.5em; }

.list-free p {
  position: relative;
  margin: 0;
  padding: 15px;
  font-size: 14px;
  line-height: 1.325em;
  letter-spacing: -0.0325em;
  border-bottom: 1px solid #ccc; }
  .list-free p strong {
    display: block;
    font-size: 17px;
    line-height: 1em; }
  .list-free p em {
    display: block;
    margin-top: 10px;
    font-size: 17px;
    font-style: normal; }
  .list-free p:nth-child(odd) {
    background-color: #eee; }

@media (min-width: 768px) {
  .list-free p em {
    position: absolute;
    top: 15px;
    right: 15px; } }

[class*="btn"] {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #959595;
  background-color: #bbb;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  border-radius: 3px;
  cursor: pointer; }
  [class*="btn"]:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #e1e1e1;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  [class*="btn"]:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.list_buttons a, .btn_link {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #006693;
  background-color: #009BDF;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .list_buttons a:hover, .btn_link:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #2dbfff;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_primary {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #3f6b25;
  background-color: #72BF44;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_primary:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #b2dc99;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_secondary {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #006693;
  background-color: #009BDF;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_secondary:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #2dbfff;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_highlight {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #b27301;
  background-color: #FEA401;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_highlight:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #febf4d;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_accent {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #4e2d89;
  background-color: #6f42c1;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_accent:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #9b7cd4;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_success {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #19692c;
  background-color: #28a745;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_success:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #48d368;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_danger {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_danger:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #e77681;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_info {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #4709ac;
  background-color: #6610f2;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_info:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #9459f6;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_review {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #c35a02;
  background-color: #fd7e14;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_review:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #fea860;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_warning {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #ba8b00;
  background-color: #ffc107;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_warning:hover {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #ffd454;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-block {
  display: block;
  text-align: center; }

.btn_close {
  float: right;
  margin: 0 0 0 15px;
  padding: 5px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  color: #fff;
  opacity: 0.8;
  cursor: pointer; }
  .btn_close .close_img {
    width: 0.75rem; }
  .btn_close:hover {
    background-color: #921925; }

.list_buttons a, .btn--sm {
  padding: 0.2em 0.75em;
  font-size: 0.85rem; }

.btn--wide {
  display: block; }

.btn_3d {
  padding: 0.75rem 1rem;
  border-width: 0.05rem;
  border-bottom-width: 0.25rem;
  -webkit-transition: all ease-in-out 150ms;
  -moz-transition: all ease-in-out 150ms;
  -ms-transition: all ease-in-out 150ms;
  -o-transition: all ease-in-out 150ms;
  transition: all ease-in-out 150ms; }
  .btn_3d:hover {
    padding: 0.85rem 1rem;
    border-bottom-width: 0.05rem;
    -webkit-transition: all ease-in-out 150ms;
    -moz-transition: all ease-in-out 150ms;
    -ms-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms; }

[class*="btn_ghost"] {
  border-color: #333;
  background: linear-gradient(to left, #fff 50%, #333 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #333;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  border-width: 2px; }
  [class*="btn_ghost"]:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-default {
  border-color: #009BDF;
  background: linear-gradient(to left, #fff 50%, #009BDF 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #009BDF;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-default:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-primary {
  border-color: #72BF44;
  background: linear-gradient(to left, #fff 50%, #72BF44 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #72BF44;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-primary:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-secondary {
  border-color: #009BDF;
  background: linear-gradient(to left, #fff 50%, #009BDF 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #009BDF;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-secondary:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-highlight {
  border-color: #FEA401;
  background: linear-gradient(to left, #fff 50%, #FEA401 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #FEA401;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-highlight:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-accent {
  border-color: #6f42c1;
  background: linear-gradient(to left, #fff 50%, #6f42c1 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #6f42c1;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-accent:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-success {
  border-color: #28a745;
  background: linear-gradient(to left, #fff 50%, #28a745 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #28a745;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-success:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-danger {
  border-color: #dc3545;
  background: linear-gradient(to left, #fff 50%, #dc3545 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #dc3545;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-danger:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-info {
  border-color: #6610f2;
  background: linear-gradient(to left, #fff 50%, #6610f2 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #6610f2;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-info:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-review {
  border-color: #fd7e14;
  background: linear-gradient(to left, #fff 50%, #fd7e14 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #fd7e14;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-review:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-warning {
  border-color: #ffc107;
  background: linear-gradient(to left, #fff 50%, #ffc107 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #ffc107;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-warning:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.coverage {
  padding: 1em;
  border: 1px solid #ddd;
  background-color: #fff; }
  .coverage > *:first-child {
    margin-top: 0; }
  .coverage > *:last-child {
    margin-bottom: 0; }

.coverage_title {
  margin-bottom: 0.5em;
  font-size: 17px;
  font-weight: 800; }

.coverage_city {
  font-size: 14px;
  line-height: 1.25em; }

.form {
  font-size: 1em; }
  .form.form_career textarea {
    height: 180px; }

.form_group-wrap {
  box-shadow: none;
  border: 1px solid #ddd;
  margin: 0 0 1.25rem;
  padding: 0 0.25rem 0.75rem; }
  .form_group-wrap p {
    padding: 0 0.75rem; }

.group_title {
  display: block;
  width: 70%;
  padding: 0.5rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 600;
  background: #f5f5f5; }

.form_security {
  overflow: hidden;
  margin-bottom: 1em; }

.form_title {
  margin: 0 0.75rem 0.5rem;
  font-size: 1.4rem;
  font-weight: 800; }
  .form_title small {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: uppercase; }

.form_group {
  padding: 0 0.75em 0.1em; }
  .form_group.form_hp {
    display: none; }

.form_error {
  border: 1px solid #dc3545;
  background-color: #fae3e5; }

.error-message {
  margin: 0 0 0.5em;
  padding: 0 0 0 3px !important;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  color: #d32535; }

.form_control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 9px 7px;
  font-size: 1rem;
  border: 1px solid #ddd;
  outline: none;
  background-color: #fff; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px; }

@media (max-width: 575px) {
  .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .form_spam .g-recaptcha {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
      position: absolute;
      top: 0;
      left: -18px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .fix-lg .form_spam {
    position: relative;
    overflow: hidden;
    height: 80px; }
    .fix-lg .form_spam .g-recaptcha {
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -o-transform: scale(0.9);
      transform: scale(0.9);
      position: absolute;
      top: 0;
      left: -2px; } }

.form_submit {
  margin-top: 0.25rem; }

@media (min-width: 768px) {
  .form_lr .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr .form_left,
  .form_lr .form_right {
    width: 50%; }
  .form_lr .form_left {
    float: left;
    clear: both; }
  .form_lr .form_right {
    float: right; }
  .form_lr .form_clear {
    clear: both; }
  .form_lr .form_state {
    width: 20%;
    clear: none; }
  .form_lr .form_zip {
    width: 30%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .form_lr--sm .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr--sm .form_left,
  .form_lr--sm .form_right {
    width: 50%; }
  .form_lr--sm .form_left {
    float: left; }
  .form_lr--sm .form_right {
    float: right; }
  .form_lr--sm .form_clear {
    clear: both; } }

.form_quote {
  padding: 0 0 0.75rem;
  border: 1px solid #ddd;
  background-color: #fff; }
  .form_quote .form_title {
    margin: 0 0 0.5rem;
    padding: 0.75rem;
    background-color: #72BF44;
    color: #fff; }

.gallery {
  margin-bottom: 1em; }

.gallery_sidebar img {
  max-width: 360px;
  margin: 0 auto 0.5em; }

.gallery_lightbox img {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

.gallery_lightbox > a {
  margin-bottom: 1%; }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even) {
      float: right; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n) {
      float: right;
      margin-right: 0; } }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 95000;
  text-align: center;
  line-height: 0;
  font-weight: 400; }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 5px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto;
  border-radius: 3px 3px 0 0; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/layout/prev.png") left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/layout/next.png") right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
  color: #fff; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close-red.svg") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

body.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99000;
  cursor: pointer; }
  .modal.show {
    display: block; }

.modal-dialog {
  position: relative;
  z-index: 99500;
  width: 90%;
  max-width: 640px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: default; }
  .modal-dialog p {
    margin-bottom: 0;
    padding: 10px 5px;
    font-size: 14px;
    line-height: 1.25em; }
    .modal-dialog p:nth-child(even) {
      background-color: #ddd; }

.modal-header {
  margin: 0 0 15px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ccc; }

.modal-title {
  margin: 15px 0 0;
  font-size: 18px; }

.nav_menu {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none; }

.menu_item {
  box-sizing: border-box;
  list-style-type: none; }
  .menu_item.active .menu_link {
    cursor: default; }
    .menu_item.active .menu_link:hover {
      cursor: default; }
  .menu_item.open > .menu_link {
    background-color: #999;
    color: #333; }
  .menu_item.open .dropdown_menu {
    display: block;
    height: auto;
    background-color: #999; }
  .menu_item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu_link {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu_link:hover, .menu_link:focus {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    outline: none; }

.nav_menu > .menu_item > .menu_link {
  padding: 18px 10px;
  background-color: #ccc;
  color: #333; }
  .nav_menu > .menu_item > .menu_link:hover, .nav_menu > .menu_item > .menu_link:focus {
    background-color: #999;
    color: #333;
    outline: none; }

.caret {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 0.85em;
  height: 0.85em;
  background-image: url("/_/images/icons/dropdown.svg");
  background-repeat: no-repeat;
  background-size: 0.85em;
  background-position: center center; }

.dropdown_menu {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  margin: 0;
  padding: 10px;
  min-width: 280px; }
  .dropdown_menu .menu_item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
    .dropdown_menu .menu_item:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
    .dropdown_menu .menu_item:not(:first-child) {
      border-top: 1px solid rgba(255, 255, 255, 0.7); }
  .dropdown_menu .menu_link {
    padding-left: 10px;
    background-color: #999;
    color: #333; }
    .dropdown_menu .menu_link:hover, .dropdown_menu .menu_link:focus {
      outline: none;
      background-color: rgba(255, 255, 255, 0.3);
      color: #333; }

.nav--pills .nav_menu > .menu_item > .menu_link {
  border-radius: 4px; }

.nav--pills .nav_menu > .open > .menu_link {
  border-radius: 4px 4px 0 0; }

.nav_list .nav_menu > .menu_item {
  margin-bottom: 0.5em; }
  .nav_list .nav_menu > .menu_item > .menu_link {
    padding: 0.75em; }

.city_menu .nav_menu > .menu_item {
  float: left;
  width: 49%; }
  .city_menu .nav_menu > .menu_item:nth-child(odd) {
    clear: both; }
  .city_menu .nav_menu > .menu_item:nth-child(even) {
    float: right; }
  .city_menu .nav_menu > .menu_item > .menu_link {
    font-size: 85% !important;
    border: 1px solid #009BDF;
    background-color: #0089c6;
    color: #fff; }
    .city_menu .nav_menu > .menu_item > .menu_link:hover {
      background-color: #00adf9; }
  .city_menu .nav_menu > .menu_item > [href="#"] {
    border-color: #aaa;
    background-color: #ddd;
    color: #333; }
    .city_menu .nav_menu > .menu_item > [href="#"]:hover {
      background-color: #ddd;
      cursor: default; }

@media (min-width: 768px) {
  .city_menu .nav_menu {
    text-align: center !important; }
  .city_menu .nav_menu > .menu_item {
    float: none;
    display: inline-block;
    width: auto; }
    .city_menu .nav_menu > .menu_item:nth-child(odd) {
      clear: none; }
    .city_menu .nav_menu > .menu_item:nth-child(even) {
      float: none; } }

.title_branding h1 {
  margin: 0;
  font-size: 16px;
  line-height: 1em; }

.branding_logo {
  display: block;
  width: 270px;
  height: 31px;
  margin: 0 auto;
  font-size: 16px;
  text-indent: -9999px;
  background-image: url("/_/images/layout/logo.png");
  background-size: contain; }
  @media (min-width: 992px) {
    .branding_logo {
      margin-left: 0;
      width: 360px;
      height: 41px; } }

.page-title {
  position: relative;
  z-index: 70000;
  padding: 2em 0;
  color: #fff;
  border-bottom: 5px solid #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); }
  .page-title h1 {
    margin: 0;
    line-height: 1em;
    text-align: center; }
  @media (min-width: 768px) {
    .page-title h1 {
      font-size: 2.5rem; } }
  @media (min-width: 992px) {
    .page-title {
      padding: 7em 0 1.5em; }
      .page-title h1 {
        text-align: left; } }
  @media (min-width: 1200px) {
    .page-title h1 {
      font-size: 2.75rem; } }
  .post .page-title h1 {
    max-width: 730px; }

.copyright_data ul > li {
  line-height: 1.1em; }

.copyright_data a {
  color: #fff;
  font-weight: 600;
  text-decoration: none; }

@media (min-width: 576px) {
  .copyright_data ul > li {
    display: inline-block; }
    .copyright_data ul > li:not(:last-child) {
      margin-right: 0.75em; } }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/layout/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.widget {
  margin: 0 0 15px;
  padding: 15px;
  font-size: 85%;
  border: 1px solid #ddd;
  background-color: #fff; }
  .widget > *:last-child {
    margin-bottom: 0; }
  .widget.widget-default .widget-title {
    color: #333; }
  .widget.widget-text {
    border-color: #333; }
    .widget.widget-text .widget-title {
      background-color: #333; }
  .widget.widget-primary {
    border-color: #72BF44; }
    .widget.widget-primary .widget-title {
      background-color: #72BF44; }
  .widget.widget-secondary {
    border-color: #009BDF; }
    .widget.widget-secondary .widget-title {
      background-color: #009BDF; }
  .widget.widget-highlight {
    border-color: #FEA401; }
    .widget.widget-highlight .widget-title {
      background-color: #FEA401; }
  .widget.widget-link {
    border-color: #009BDF; }
    .widget.widget-link .widget-title {
      background-color: #009BDF; }
  .widget.widget-review {
    border-color: #fd7e14; }
    .widget.widget-review .widget-title {
      background-color: #fd7e14; }
  .widget.widget-success {
    border-color: #28a745; }
    .widget.widget-success .widget-title {
      background-color: #28a745; }
  .widget.widget-danger {
    border-color: #dc3545; }
    .widget.widget-danger .widget-title {
      background-color: #dc3545; }
  .widget.widget-info {
    border-color: #6610f2; }
    .widget.widget-info .widget-title {
      background-color: #6610f2; }
  .widget.widget-warning {
    border-color: #ffc107; }
    .widget.widget-warning .widget-title {
      background-color: #ffc107; }

.widget-title:not(.plain) {
  margin: -15px -15px 15px;
  padding: 12px 15px;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #ddd;
  color: #fff; }

.widget-title.plain {
  margin: 0 0 5px; }

.widget_plain {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent; }
  .widget_plain .widget-title {
    margin: 0 0 15px;
    padding: 0px;
    background-color: transparent;
    color: inherit; }

@media (min-width: 768px) and (max-width: 991px) {
  .widget-rwreviews {
    overflow: hidden; }
    .widget-rwreviews p {
      float: left;
      width: 49%; }
      .widget-rwreviews p:nth-child(odd) {
        clear: both; }
      .widget-rwreviews p:nth-child(even) {
        float: right; } }

.widget-pleads {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-width: 3px;
  border-color: #07b; }
  .widget-pleads .widget-title {
    background-color: #07b; }
  .widget-pleads img {
    max-width: 240px;
    margin: 0 auto 0.5em; }

.banner {
  padding: 2em 0 1.9em;
  border-bottom: 5px solid #ddd;
  box-shadow: 0 0.02em 0.75em 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  color: #333; }

.site_info {
  margin-top: 1em;
  border-top: 5px solid #fff;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #72BF44;
  color: #fff;
  font-size: 0.9em; }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 15px; } }

@media (min-width: 992px) {
  .sidebar .form_submit input {
    display: block;
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .sidebar .form_spam .g-recaptcha {
      -webkit-transform: scale(0.85);
      -moz-transform: scale(0.85);
      -o-transform: scale(0.85);
      transform: scale(0.85);
      position: absolute;
      top: 0;
      left: -8px; } }

.sidebar > * {
  margin-bottom: 15px; }
